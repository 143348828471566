import React from 'react';
import lantern from './lantern.png'
import styles from './Header.module.css';

const Header = (props) => (
  <div className={styles.Header} data-testid="Header">
      <div className={styles.logo}>
          <img src={lantern} alt="logo"/>
      </div>
      <div className={styles.title}>Расписание <br/> {props.date}</div>
      <div className={styles.switchTheme}>dumvolga lite <br/><br/>volgograd</div>
  </div>
);

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
