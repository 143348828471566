import React, {useEffect, useState} from 'react';
import styles from './Schedule.module.css';
import fajr from './fajr.png'
import dhuhr from './dhuhr.png'
import asr from './asr.png'
import maghrib from './maghrib.png'
import isha from './isha.png'


function Schedule(props) {
    let [active, setActive] = useState(undefined)
    let todayDate = new Date();
    let current = `${("0" + todayDate.getHours()).slice(-2)}:${todayDate.getMinutes()}`

    const getActive = () => {
        todayDate = new Date();
        current = `${("0" + todayDate.getHours()).slice(-2)}:${todayDate.getMinutes()}`

        setTimeout(() => {
            getActive();
        }, 3000);

        if (current <= props.schedule.fajr) {
            setActive('fajr');
            return;
        }
        if (current >= props.schedule.fajr && current < props.schedule.sunrise) {
            setActive('sunrise');
            return;
        }
        if (current >= props.schedule.sunrise && current < props.schedule.zuhr) {
            setActive('sunrise');
            return;
        }
        if (current >= props.schedule.zuhr && current < props.schedule.asrH) {
            setActive('zuhr');
            return;
        }
        if (current >= props.schedule.asrH && current < props.schedule.maghrib) {
            setActive('asr');
            return;
        }
        if (current >= props.schedule.maghrib && current < props.schedule.isha) {
            setActive('maghrib');
            return;
        }
        if (current >= props.schedule.isha) {
            setActive('isha');
        }
    }

    useEffect(() => {
        if (!active) {
            getActive();
        }
    }, [])


    return (
        <div className={styles.Schedule} data-testid="Schedule">
            <div className={styles.schedule__item + ' ' + (active === 'fajr' && styles.active)}>
                <img src={fajr} className={styles.schedule__item__icon}></img>
                <div className={styles.schedule__item__title}>Фаджр</div>
                <div className={styles.schedule__item__time}>{props.schedule.fajr}</div>
            </div>
            <div className={styles.schedule__item + ' ' + (active === 'sunrise' && styles.active)}>
                <img src={fajr} className={styles.schedule__item__icon}></img>
                <div className={styles.schedule__item__title}>Восход</div>
                <div className={styles.schedule__item__time}>{props.schedule.sunrise}</div>
            </div>
            <div className={styles.schedule__item + ' ' + (active === 'zuhr' && styles.active)}>
                <img src={dhuhr} className={styles.schedule__item__icon}></img>
                <div className={styles.schedule__item__title}>Зухр</div>
                <div className={styles.schedule__item__time}>{props.schedule.zuhr}</div>
            </div>
            <div className={styles.schedule__item + ' ' + (active === 'asr' && styles.active)}>
                <img src={asr} className={styles.schedule__item__icon}></img>
                <div className={styles.schedule__item__title}>Аср</div>
                <div className={styles.schedule__item__time}>{props.schedule.asr}</div>
            </div>
            <div className={styles.schedule__item + ' ' + (active === 'asr' && styles.active)}>
                <img src={asr} className={styles.schedule__item__icon}></img>
                <div className={styles.schedule__item__title}>Аср 2</div>
                <div className={styles.schedule__item__time}>{props.schedule.asrH}</div>
            </div>
            <div className={styles.schedule__item + ' ' + (active === 'maghrib' && styles.active)}>
                <img src={maghrib} className={styles.schedule__item__icon}></img>
                <div className={styles.schedule__item__title}>Вечерний</div>
                <div className={styles.schedule__item__time}>{props.schedule.maghrib}</div>
            </div>
            <div className={styles.schedule__item + ' ' + (active === 'isha' && styles.active)}>
                <img src={isha} className={styles.schedule__item__icon}></img>
                <div className={styles.schedule__item__title}>Ночной</div>
                <div className={styles.schedule__item__time}>{props.schedule.isha}</div>
            </div>
        </div>
    );
}

export default Schedule;
