import './App.css';
import Header from "./components/Header/Header";
import Schedule from "./components/Schedule/Schedule";
import schedules from './schedules.json'

function App() {
    const todayDate = new Date();

    const months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];
    const currentMonth = todayDate.getMonth();
    const todayDay = todayDate.getDate();

    return (
        <div>
            <Header date={`на ${todayDay} ${months[currentMonth]}`}></Header>
            <div></div>
            <Schedule schedule={schedules.sh[currentMonth][todayDay - 1]}></Schedule>
        </div>
    );
}

export default App;
